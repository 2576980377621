import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NbToastrService, NbWindowService } from '@nebular/theme';
import { EMPTY, from, Observable, of, switchMap, throwError } from 'rxjs';

import { ErrorMethod, SuccessMethod } from './service.model';
import { ApiError } from '@patron-administration/api';
import { ErrorForcingComponent } from '../components/error-forcing/error-forcing.component';
import { map } from 'rxjs/operators';
import { FirestoreError } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class UtilsRestService {

  constructor(
    private toastrService: NbToastrService,
    private nbWindowsService: NbWindowService,
  ) {
  }

  handleError(err: HttpErrorResponse, $event: any, method: ErrorMethod, entityName: string): Observable<any>;
  handleError(err: HttpErrorResponse, $event: any, method: ErrorMethod, entityName: string, forceCallback?: (...args: any[]) => Observable<any>, forceCallbackArgs?: any[]): Observable<any>;
  handleError(err: HttpErrorResponse, $event: any, method: ErrorMethod, entityName: string, forceCallback?: (...args: any[]) => Observable<any>, forceCallbackArgs?: any[]): Observable<any> {

    return this.getErrorMessage(err).pipe(switchMap((m: string) => {
      if (forceCallback && this.canErrorBeForced(err)) {
        this.nbWindowsService.open(ErrorForcingComponent, {
          title: 'Do you want to try to enforce the action?',
          windowClass: 'window-form',
          context: {
            forceCallback,
            forceCallbackArgs,
            errorMessage: m,
          }
        });
        return EMPTY;
      } else {
        this.handleErrorInternal(m, method, entityName);
        $event?.confirm?.reject();
        return throwError(() => err);
      }
    }));
  }

  handleFirestoreError(err: FirestoreError): Observable<any> {
    const message = err?.message?.length > 150 ?
      (err?.message.slice(0, 150) + ' ... (details in console)') :
      err?.message;
    this.toastrService.danger(message, 'Firestore error');
    console.error(err);
    return throwError(() => err);
  }

  handleSuccess(response: HttpResponse<any>, $event: any, method: SuccessMethod, entityName: string): void {
    const message = method.format(entityName);
    if (SuccessMethod.CREATE === method && $event) {
      const newId = this.parseIdFromLocation(response);
      $event.newData.id = newId;
      $event?.confirm?.resolve($event.newData);
    } else {
      $event?.confirm?.resolve();
    }

    this.toastrService.success(message, 'Backend');
  }

  parseIdFromLocation(response: HttpResponse<any>): string {
    let newId = response?.headers?.get('Location');
    if (newId && newId.includes('/')) {
      const split = newId.split('/');
      newId = split[split.length - 1];
    }
    return newId;
  }

  private handleErrorInternal(errorMessage: string, method: ErrorMethod, entityName: string): void {
    const title = method.format(entityName);
    this.toastrService.danger(errorMessage ? errorMessage : '', title);
  }

  private getErrorMessage(err: HttpErrorResponse): Observable<string> {
    const appError: Blob | ApiError = err?.error;

    const apiErrorObj = appError instanceof Blob ?
      from(appError.text()).pipe(map(o => JSON.parse(o))) :
      of(appError);

    return apiErrorObj.pipe(
      map((apiError: ApiError) => {
        let errorMessage = apiError?.message;
        if (err?.status === 500 && apiError?.details?.length > 0) {
          errorMessage = apiError.details.map(e => e.message).join(',');
        }
        return errorMessage;
      })
    );
  }

  private canErrorBeForced(err: HttpErrorResponse): boolean {
    const appError: ApiError = err?.error;
    return appError?.code === 'PATRON_ERR_1080' || appError?.code === 'PATRON_ERR_1055';
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitString'
})
export class LimitStringPipe implements PipeTransform {
  transform(value: string, numberOfChars?: number): any {
    if (numberOfChars == null || value?.length < numberOfChars) {
      return value;
    }
    else {
      return value.slice(0, numberOfChars) + '...';
    }
  }
}
